import type {WrapTextProps} from '@jetbrains/ring-ui/components/link/link'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import type {ListDataItem} from '@jetbrains/ring-ui/components/list/consts'
import type {ComponentType} from 'react'
import {Fragment, useCallback, useMemo} from 'react'

import {useSetUserProperty} from '../../../hooks/useSetUserProperty'
import {getServerInfo} from '../../../selectors'
import {BS, internalProps} from '../../../types/BS_types'
import {truthy} from '../../../utils/guards'
import {resolveHelpURL} from '../../../utils/url'
import {UserProperties} from '../../../utils/userProperties'
import {useIsHintsModeEnabled} from '../../packages/Hints/Hints.hooks'
import SvgIcon from '../SvgIcon/SvgIcon'

import {getSupportUrl} from './HelpDropdown.utils'

import styles from './HelpDropdown.css'

import {useAppSelector} from 'src/hooks/react-redux'

const supportLinkEnabled = internalProps['teamcity.ui.supportLink.enabled']
export const UNIT = 8
export const whatsNewHref: string = resolveHelpURL("?What's+New+in+TeamCity")
export const aboutSakuraUIHref: string = resolveHelpURL('teamcity-sakura-ui.html')
export function useHelpDropdownContent(): ReadonlyArray<ListDataItem> {
  const {isEnabled, isGuestOrRoot} = useIsHintsModeEnabled()
  const setUserProperty = useSetUserProperty()
  const toggleTipsMode = useCallback(() => {
    setUserProperty(UserProperties.TIPS_MODE, isEnabled ? 'false' : 'true')
  }, [setUserProperty, isEnabled])

  const {licenseIsCloud, licenseIsEnterpise, version} = useAppSelector(getServerInfo) || {}

  return useMemo(
    () =>
      [
        {
          label: 'Documentation',
          href: resolveHelpURL('?TeamCity Documentation'),
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        supportLinkEnabled &&
          (licenseIsCloud || licenseIsEnterpise) && {
            label: 'Support',
            href: getSupportUrl(licenseIsCloud, licenseIsEnterpise, version),
            rgItemType: ListItemType.LINK,
            rel: 'noreferrer',
          },
        {
          label: 'Feedback',
          href: BS?.feedbackUrl,
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        {
          label: 'Getting started',
          href: resolveHelpURL('?Configure and Run Your First Build'),
          rgItemType: ListItemType.LINK,
          rel: 'noreferrer',
        },
        !isGuestOrRoot && {
          key: 'enable-tips',
          rgItemType: ListItemType.LINK,
          label: `${isEnabled ? 'Hide' : 'Show'} hints`,
          onClick: toggleTipsMode,
        },
        {
          key: 'about-sakura-ui',
          className: styles.customLink,
          href: aboutSakuraUIHref,
          children: (WrapText: ComponentType<WrapTextProps>) => (
            <Fragment>
              <WrapText>{'About Sakura UI'}</WrapText>
            </Fragment>
          ),
          rel: 'noreferrer',
        },
        {
          key: 'whats-new',
          className: styles.customLink,
          href: whatsNewHref,
          children: (WrapText: ComponentType<WrapTextProps>) => (
            <Fragment>
              <SvgIcon icon="gift" className={styles.customLinkIcon} />
              <WrapText>{"What's new"}</WrapText>
            </Fragment>
          ),
          rel: 'noreferrer',
        },
      ].filter(truthy),
    [isEnabled, isGuestOrRoot, licenseIsCloud, version, licenseIsEnterpise, toggleTipsMode],
  )
}
