import type {CombinedState} from 'redux'

import type {BuildId, ProblemOccurrenceId, TestOccurrenceId} from '../../../types'
import type {KeyValue} from '../../../utils/object'

export type LogFilter = 'debug' | 'important' | 'err'
type BuildLogOption = {
  count?: (number | null | undefined) | ReadonlyArray<number>
  logAnchor?: number
  expandState?: ReadonlyArray<number> | null | undefined
  expandSubtree?: number
  expandFailures?: boolean
  expandAll?: boolean
  stageKey?: string
  target?: string
  testOccurrenceId?: TestOccurrenceId
  problemOccurrenceId?: ProblemOccurrenceId
  filter?: LogFilter | null | undefined
  searchQuery?: string
  logView?: LogView
}

export enum Status {
  INFO = 1,
  WARNING = 2,
  TRACE_ERROR = 3,
  ERROR = 4,
}

export enum PointType {
  FAILURE = 'FAILURE',
  NOW = 'NOW',
}

export type BuildLogMessage = {
  id: number
  parentId?: number | null | undefined
  blockType?: string | null | undefined
  containsMessages?: boolean | null | undefined
  text: string
  status: Status
  level: number
  timestamp?: string
  serverTimestamp?: string
  duration?: number | null | undefined
  flowId?: number | null | undefined
  linkedMessageId?: number | null | undefined
  verbose?: boolean | null | undefined
  // appended on client properties
  processed?: boolean
  childrenEmpty?: boolean
  nextIsHole?: boolean
  containAnsi?: boolean | null | undefined
  containUrl?: boolean | null | undefined
  inaccurateSize?: boolean | null | undefined
  isBroken?: boolean | null | undefined
  isLast?: boolean | null | undefined
  linesLengths?: ReadonlyArray<number>
  maxLineLength?: number
}
export type BuildLogMessagesResponseAnchor = {
  position: number
  type: string
}
export enum TestTargetAnchorType {
  TEST_START = 'TEST_START',
  TEST_EXCEPTION = 'TEST_EXCEPTION',
  TEST_ACTUAL = 'TEST_ACTUAL',
  TEST_EXPECTED = 'TEST_EXPECTED',
}

export type BuildLogTestTargetAnchor = {
  position: number
  type: TestTargetAnchorType
}
type BuildLogTestTarget = {
  anchors: ReadonlyArray<BuildLogTestTargetAnchor>
  id: number
  type: 'TEST'
}
export type BuildLogMessagesResponse = {
  messages: ReadonlyArray<BuildLogMessage>
  expandedMessagesIndices?: ReadonlyArray<number>
  testTarget?: BuildLogTestTarget
  stageTarget?: {
    key: string
    anchors: ReadonlyArray<BuildLogMessagesResponseAnchor>
  }
  lastMessageIncluded?: boolean
  nextVisible?: number
  prevVisible?: number
  view?: LogView
  focusIndex?: number
}
type TimelinePoint = {
  id: number
  x: number
  name?: string | null | undefined
  buildAnchor: number
  type?: PointType
}
export type TimelineStageStatus = 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'UNKNOWN'
export type TimelineStage = {
  key: string
  name: string
  start: number
  end: number
  // Milliseconds
  duration: number
  point?: TimelinePoint
  statuses: ReadonlyArray<TimelineStageStatus> | null | undefined
  now?: {
    x: number
  }
}
export type BuildLogTimeline = {
  stages: ReadonlyArray<TimelineStage>
  nowPoint?: number | null | undefined
}
export type BuildLogKey = string
export type FullLogScrollBarInfo = {
  horizontal: boolean
  size: number | null | undefined
  vertical: boolean
}
export type FullLogLoadingDirection = 'up' | 'down' | null
export type LogView = 'flowAware' | 'linear' | null | undefined
export type FullLogState = {
  buildId: BuildId | null | undefined
  focusLine: number | null | undefined
  expandState: ReadonlyArray<number> | null | undefined
  expandedChildren?: ReadonlyArray<KeyValue<number, number[]>> | null
  expandAll: boolean
  showedFirstDate: string | null | undefined
  showedFirstId: number | null | undefined
  showedLastId: number | null | undefined
  showedLastDate: string | null | undefined
  scrollBarInfo: FullLogScrollBarInfo | null | undefined
  messagesExists: boolean
  filter: LogFilter | null | undefined
  lastLoadingDirection: FullLogLoadingDirection
  logView: LogView
}
export type FullLogTarget = 'page' | 'popup'
export type FetchMessagesParams = {
  buildId: BuildId
  buildLogKey: BuildLogKey
  options?: BuildLogOption
  invalidate?: boolean
  target?: FullLogTarget
  mergeData?: boolean
}
export enum SearchDirection {
  NAME = 'Next',
  PREVIOUS = 'Previous',
}
export type MessagesFetchable = {
  data: ReadonlyArray<BuildLogMessage>
  ready: boolean
  loading: boolean
}
export type MessagesState = KeyValue<BuildLogKey, MessagesFetchable>
export type TestAnchorsState = KeyValue<BuildLogKey, BuildLogTestTarget | null>
export type LastMessageIncludedState = KeyValue<BuildLogKey, boolean | null>
export type MessagesLoadState = KeyValue<
  string,
  {
    loading: boolean
    lastLoadedTime: number | null | undefined
  }
>
type FullLogStates = {
  page: FullLogState
  popup: FullLogState
}
type LogSettingsState = {
  softWrapLines: boolean
  relativeTime: boolean
}
export type BuildLogSearchState = {
  query: string | null | undefined
  nextStartId: number | null | undefined
  foundId: number | null | undefined
  matchPosition: number | null | undefined
  matchesCount: number
  startId: number | null | undefined
  limitReachedTimes: number
  notFound: boolean
  loading: boolean
  searchDirection: SearchDirection
}
type BuildLogSearchStates = {
  page: BuildLogSearchState
  popup: BuildLogSearchState
}
export type BuildLogState = CombinedState<{
  messages: MessagesState
  testAnchors: TestAnchorsState
  lastMessageIncluded: LastMessageIncludedState
  fullLogStates: FullLogStates
  messagesLoadStates: KeyValue<BuildLogKey, MessagesLoadState>
  settings: LogSettingsState
  searchStates: BuildLogSearchStates
}>
export type KeyBuildLogParams =
  | {
      type: 'preview'
      id: BuildId | null | undefined
    }
  | {
      type: 'test'
      id: TestOccurrenceId | null | undefined
    }
  | {
      type: 'problem'
      id: ProblemOccurrenceId | null | undefined
    }
  | {
      type: 'full'
      target: FullLogTarget
    }

export type BuildLogMessageSelectionStart = {
  direction: 'down' | 'up'
  index: number
  messageId: number
  timeSelected: boolean
}
